<template>
  <div
    style="
      box-shadow: 0px 4px 10px rgba(10, 4, 17, 0.15);
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    "
  >


        <!-- Image Start -->
    <div class=" bg-preload"
        style="height: 200px; width:100%">
                 <div class="into-preload"></div>
        </div>
        <!-- Image End -->
         <div class="bg-preload mt-2 "
        style="height:50px; width:100%">
                 <div class="into-preload "></div>
        </div>

        

  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.integrationDiv {
  background: #ffffff;
  box-shadow: 0px -2px 16px rgba(77, 27, 126, 0.1);
  border-radius: 20px;
  width: 100%;
  /* min-height:250px; */
  height: 100%;
}
</style>
