<template>
  <div class="IntegrationCarouselMain">
    <div class="topSection d-flex justify-content-between align-items-center">
        <p class="title m-0">Integration Market</p>
         <router-link to="/integrations/list" class="IntegrationLink">All Integrations</router-link>
    </div>
    <div class="CarouselSection mt-3">
        <div class="row " v-if="isIntegrationLoading">
            <div
                  class="col-6 col-md-4  col-lg-3 mb-3"
                  v-for="index in 4"
                  :key="index"
                >
                  <AllIntegrationSkeleton />
                </div>
        </div>
        <div class="row" v-else>
            <div class="col-12">
                <carousel :perPage="3" class="IntegrationCarousel pr-3 "  :paginationEnabled="true
                            "
                            :navigationEnabled="true">
                    <slide  v-for="(integration, index) in Integrations"
                    :key="index" class="mx-auto px-3" >
                    <div
                      class="integrationDiv pt-4 px-4"
                      @click.stop="openIntegration( integration.id)"
                    >
                      <div
                        class="header-section d-flex justify-content-between"
                      >
                        <div class="integrationImgae">
                          <LazyImage
                            :src="`${integration.image}`"
                            :key="integration.image"
                            style="
                              width: 100%;
                              height: 100%;
                              border-radius: 14px;
                            "
                            :alt="integration.title"
                            :title="integration.title"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <div class="mid-section pt-4 pb-3">
                        <div class="integration-title">
                          <p class="">{{ integration.title }}</p>
                        </div>
                        <div class="integration-desc">
                          <p class="m-0">{{ integration.short_description }}</p>
                        </div>
                      </div>
                    </div>
                    
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { Carousel, Slide } from "vue-carousel";
import AllIntegrationSkeleton from "./AllIntegrationSkeleton.vue"
export default {
    components:{
        AllIntegrationSkeleton,
    Carousel,
    Slide,
    },
data(){
    return{
       Integrations: [],
        isIntegrationLoading: false,
    }
},
methods:{
     openIntegration(id) {
         this.$router.push(`/integrations/${id}`)
    },
    async getIntegrationList() {
      this.isIntegrationLoading = true;
      try {
        let data = {
          keyword: this.searchIntegration,
          categories: this.selectedCategory,
        };
        const response = await axios.get(`/getIntegrationApps`, {
          params: data,
        });
        if (response.status == 200) {
          this.Integrations = response.data.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      } finally {
        this.isIntegrationLoading = false;
      }
    },
},
mounted(){
    this.getIntegrationList()
}
}
</script>

<style scoped>
.topSection .title{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 39px;
color: #292929;
}
.topSection .IntegrationLink{
 font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #FFA201;
}
.IntegrationCarouselMain .integrationDiv {
  background: #ffffff;
  box-shadow: 0px -2px 16px rgba(77, 27, 126, 0.1);
  border-radius: 20px;
  width: 100%;
  /* min-height:250px; */
  height: 100%;
}
.IntegrationCarouselMain .integrationDiv:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.IntegrationCarouselMain .header-section .integrationImgae {
  width: 70px;
  height: 70px;
  border-radius: 14px;
  /* min-height:70px; */
  /* height:auto; */
}
.IntegrationCarouselMain .header-section .planText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
}
.IntegrationCarouselMain .mid-section .integration-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  color: #1f1f28;
}
.IntegrationCarouselMain .mid-section .integration-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #7c8290;
}

.IntegrationCarousel :deep(.VueCarousel-pagination){
  height: 40px !important;
  visibility: hidden !important;
}
.IntegrationCarousel :deep(.VueCarousel-navigation) {
  position: absolute;
  top: 99%;
  left: 50%;
}
.IntegrationCarousel  :deep(.VueCarousel-navigation) button{
 background: #FFA201;
 color:#ffffff;
}
.IntegrationCarousel  :deep(.VueCarousel-navigation) .VueCarousel-navigation-next{
right:-9px;
}

</style>