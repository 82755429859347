<template>
  <div class="">
    <HeaderVue heading="Your Quizzes" class="pb-3">
      <template v-slot:buttons>
        <div class="d-flex align-items-center">
          <FreightQuotMarginPrice
            v-if="user.id == 23096 || user.id == 23128"
            class="mr-2"
          ></FreightQuotMarginPrice>

          <div
            v-if="getIsAccountDataLoading"
            class="bg-preload"
            style="height: 40px; width: 130px; border-radius: 8px"
          >
            <div class="into-preload"></div>
          </div>
          <Button v-else class="ButtonHover" @click="openCreateQuiz">
            + Create Quiz</Button
          >
        </div>
      </template>
    </HeaderVue>
    <div class="card DashboardMainStyles border-radius">
      <div class="card-body">
        <div class="row" v-if="isDashboardLoading">
          <div class="col-12 mt-2 col-md-6" v-for="index in 4" :key="index">
            <CreateQuizSkeleton />
          </div>
        </div>

        <div class="" v-else>
          <div>
            <EditQuizModal
              ref="EditQuizModalRef"
              @UpdateDashboard="updateDashboardData"
            ></EditQuizModal>
            <DuplicateQuizModal
              ref="DuplicateQuizModalRef"
              @UpdateDashboard="updateDashboardData"
            ></DuplicateQuizModal>
            <!-- Top Section end -->
            <!-- Mid Section start -->
            <div class="">
              <!-- Your Quizzes start -->

              <div class="yourQuizzes py-4">
                <div :class="[this.getUserType !== 'web' ? ' ' : '']">
                  <div class="">
                    <div class="row mt-3" v-if="Dashboard.length > 0">
                      <div
                        class="mb-4"
                        :class="getColumn"
                        v-for="(quiz, index) in Dashboard"
                        :key="index"
                        style="z-index: 11"
                      >
                        <!-- Main Comp -->
                        <div class="quizMainDev" style="background: #ffffff">
                          <div>
                            <div
                              @click="openCustomize(quiz)"
                              class="topSection position-relative"
                              style="
                                height: 300px;
                                width: 100%;
                                border-top-left-radius: 12px;
                                border-top-right-radius: 12px;
                                background: #fff;
                              "
                            >
                              <GetStartedPagePreviewComp
                                v-if="quiz.first_page.value == 'getStartedPage'"
                                :key="quiz.id + `getStarted`"
                                :height="300"
                                :width="200"
                                :mode="'firstPage'"
                                :pageDesign="quiz.first_page.pageDesign"
                                :order="quiz.first_page.order"
                              />
                              <QuestionLeadsStripePage
                                v-else
                                :key="quiz.id + `Question`"
                                :height="300"
                                :width="200"
                                :mode="'firstPage'"
                                :CurrentPageValue="quiz.first_page.value"
                                :pageDesign="quiz.first_page.pageDesign"
                                :order="quiz.first_page.order"
                              />

                              <button
                                @click.stop="
                                  () => {
                                    quizKey = quiz.quizKey;
                                    QuizPreview = true;
                                  }
                                "
                                class="PreviewBtn outline-none border-0 px-2 py-1"
                                style="
                                  position: absolute;
                                  right: 4%;
                                  top: 5%;
                                  border-radius: 6px;
                                "
                              >
                                <b-icon-eye-fill
                                  class="BtnIcon"
                                ></b-icon-eye-fill>
                              </button>
                            </div>

                            <div
                              class="p-2 d-flex justify-content-between align-items-center"
                            >
                              <p
                                @click="openCustomize(quiz)"
                                class="quiz-title m-0"
                                style="font-size: 16px; cursor: pointer"
                              >
                                {{ quiz.title }}
                              </p>

                              <div>
                                <b-dropdown
                                  dropup
                                  right
                                  class="QuizDropdown"
                                  id=""
                                  style="z-index: 1111"
                                >
                                  <template
                                    slot="button-content"
                                    class="templateButton"
                                  >
                                    <b-icon-three-dots-vertical
                                      class="font-weight-bold"
                                      style="color: #6d6b6d"
                                    ></b-icon-three-dots-vertical>
                                  </template>
                                  <b-dropdown-item
                                    @click="openCustomize(quiz)"
                                    class="d-flex align-items-center"
                                    ><b-icon-brush class="mr-3"></b-icon-brush
                                    >Customise</b-dropdown-item
                                  >
                                  <b-dropdown-item
                                    @click="openShare(quiz)"
                                    class="d-flex align-items-center"
                                    ><b-icon-share-fill
                                      class="mr-3"
                                    ></b-icon-share-fill
                                    >Share</b-dropdown-item
                                  >
                                  <b-dropdown-item
                                    @click="openEditQuizModal(quiz)"
                                    class="d-flex align-items-center"
                                    ><b-icon-pencil class="mr-3"></b-icon-pencil
                                    >Change name</b-dropdown-item
                                  >

                                  <b-dropdown-item
                                    @click="duplicateQuiz(quiz)"
                                    class="trash d-flex align-items-center"
                                    ><b-icon-files
                                      class="text-white mr-3"
                                    ></b-icon-files
                                    ><span class="text-white"
                                      >Duplicate</span
                                    ></b-dropdown-item
                                  >

                                  <b-dropdown-item
                                    @click="removeQuiz(quiz)"
                                    class="trash d-flex align-items-center"
                                    ><b-icon-trash
                                      class="text-white mr-3"
                                    ></b-icon-trash
                                    ><span class="text-white"
                                      >Delete</span
                                    ></b-dropdown-item
                                  >
                                </b-dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div
                          v-if="Dashboard.length < pagination.total"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <Button
                            :is-loading="loadMoreQuizzes"
                            @click="getDashboardData"
                            >Load More</Button
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      class="row mt-4 mb-n5"
                      v-else
                      :style="
                        this.getUserType !== 'web' ? 'text-align:center;' : null
                      "
                    >
                      <div class="col-12" style="position: realtive">
                        <b-img
                          src="/assets/img/emptyBanner.svg"
                          fluid
                          style="width: 100%"
                          alt="Responsive image"
                        ></b-img>
                        <div class="CreateButton">
                          <div
                            v-if="getIsAccountDataLoading"
                            class="bg-preload"
                            style="
                              height: 40px;
                              width: 130px;
                              border-radius: 8px;
                            "
                          >
                            <div class="into-preload"></div>
                          </div>
                          <button
                            v-else
                            class="btn createfirstQuiz"
                            @click="openCreateQuiz"
                          >
                            Create your first Quiz
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Integation Carousel Start -->
                    <div class="mt-5" v-if="false">
                      <IntegrationCarouselComp />
                    </div>
                    <!-- Integation Carousel End -->

                    <!-- Accounts Stats -->
                    <div
                      class="mt-2"
                      :class="
                        this.getUserType !== 'web' && !Dashboard.length
                          ? ' d-flex justify-content-center'
                          : null
                      "
                      v-if="false"
                    >
                      <div
                        class="row py-5"
                        :style="
                          this.getUserType !== 'web' && !Dashboard.length
                            ? 'width:80%'
                            : null
                        "
                      >
                        <div class="col-12">
                          <p class="font-weight-bold" style="font-size: 26px">
                            Account Statistics
                          </p>
                        </div>
                        <div class="col-12 col-md-8 col-lg-3 mt-1">
                          <div
                            class="pb-3"
                            style="
                              background: linear-gradient(
                                180deg,
                                #ffb800 0%,
                                #ffa200 56.77%,
                                #ffa200 90.1%
                              );
                              border-radius: 16px;
                            "
                          >
                            <p class="plan-name pt-2 m-0 pl-4">
                              {{ plan.name || "Plan" }}
                            </p>
                            <div
                              class="d-flex justify-content-start pb-3 pt-3 pl-4"
                            >
                              <!--  -->

                              <button
                                type="button"
                                @click="openPlansModal"
                                class="btn px-3 pt-2 pb-2 UpgradeBtn"
                                v-if="getUserType != 'shopify'"
                                style=""
                              >
                                Upgrade Plan
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 col-md-8 col-lg-8 col-xl-5 Limitations"
                        >
                          <div style="">
                            <p
                              class="pt-1"
                              style="font-size: 22px; font-weight: 600"
                            >
                              Limitations reach
                            </p>
                            <div class="row">
                              <div
                                class="col-12 col-md-6 col-lg-6 mb-4"
                                v-for="(limitation, index) in Limitations"
                                :key="index"
                                v-show="checkLimitations(limitation)"
                              >
                                <div
                                  class="d-flex align-items-center mb-2 text-wrap"
                                >
                                  <h6 class="progress-head m-0 mr-3">
                                    {{ limitation.text }}
                                  </h6>
                                  <span class="limitation-value"
                                    >{{ limitation.userValue + "/"
                                    }}{{ limitation.planValue }}</span
                                  >
                                </div>
                                <div>
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      :class="
                                        limitation.userValue ==
                                        limitation.planValue
                                          ? 'bg-red-theme'
                                          : 'bg-yello-theme'
                                      "
                                      :style="`width:${
                                        (limitation.userValue /
                                          limitation.planValue) *
                                        100
                                      }%`"
                                    ></div>
                                  </div>
                                  <div
                                    v-if="limitation.text == 'Engagements'"
                                    class="mt-1"
                                  >
                                    <span
                                      class=""
                                      style="color: #b3afb6; font-size: 13px"
                                      >Next reset on :
                                      {{ limitation.revisedDate }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-md-5" style="display: none">
                          <div v-if="user">
                            <div class="row align-items-center pt-2">
                              <div class="col-md-2">
                                <LazyImage
                                  v-if="user.image"
                                  class=""
                                  :key="user.image"
                                  :src="ImageSrc(user.image)"
                                  style="
                                    width: 68px;
                                    height: 68px;
                                    border-radius: 50%;
                                  "
                                />
                                <LazyImage
                                  v-else
                                  class=""
                                  :src="'/images/default_start_page.png'"
                                  style="
                                    width: 68px;
                                    height: 68px;
                                    border-radius: 50%;
                                  "
                                />
                              </div>
                              <div class="col-md-9 ml-3">
                                <div>
                                  <h5
                                    style="
                                      color: #292929;
                                      font-size: 22px;
                                      font-weight: 600;
                                    "
                                  >
                                    {{ user.name || "User" }}
                                  </h5>
                                  <p
                                    class="mb-0"
                                    style="color: #292929; font-weight: 400"
                                  >
                                    {{ user.email }}
                                  </p>
                                </div>
                              </div>
                              <div
                                class="col-12 pt-2 d-flex justify-content-center"
                              >
                                <div
                                  style="
                                    border-bottom: 1px solid #e0dee2;
                                    width: 98%;
                                  "
                                  class=""
                                ></div>
                              </div>
                            </div>
                            <div class="row pt-2">
                              <div class="col-md-12">
                                <p
                                  class="mb-0"
                                  style="font-weight: 600; color: #4d4950"
                                >
                                  Phone number:
                                  <span
                                    v-if="user.phone"
                                    style="font-weight: 400"
                                  >
                                    {{ user.phone }}</span
                                  >
                                  <span
                                    v-else
                                    class="ml-2"
                                    style="font-weight: 400"
                                  >
                                    Not provided!</span
                                  >
                                </p>
                              </div>
                            </div>
                            <div class="row py-3 d-none">
                              <div class="col-md-8">
                                <div>
                                  <span style="font-weight: 600; color: #4d4950"
                                    >Payment method:
                                  </span>
                                  <span
                                    style="
                                      background: #0fd552;
                                      color: #ffffff;
                                      padding: 4px 12px;
                                      border-radius: 22px;
                                      font-size: 16px;
                                      margin-left: 12px;
                                    "
                                    >Confirmed</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-12">
                                <button
                                  type="button"
                                  @click="openAccount()"
                                  class="btn btn-profile-seeting"
                                >
                                  Profile Settings
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Your Quizzes end -->
            </div>
            <!-- Mid Section end -->
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      :isLoading="deleteSpinner"
      @click="DeleteQuizHandle"
      ref="deleteQuiz"
      id="deleteQuiz"
      title="Delete Quiz"
      item="quiz"
    ></DeleteModal>
    <!-- Modals -->
    <UpgradePlanModal
      ref="UpgradeUserPlanModal"
      :title="upgradeModalTitle"
      :subText="upgradeModalsubText"
      @openPlansModalChild="openPlansModal"
    />
    <CreateQuizCategory ref="createQuiz"></CreateQuizCategory>
    <TestPreviewModal
      :visible="QuizPreview"
      @back="QuizPreview = false"
      :quizKey="quizKey"
    >
    </TestPreviewModal>
  </div>
</template>
<script>
import Button from "./Layout/Button.vue";
import { mapGetters, mapMutations } from "vuex";

import axios from "axios";
// import LoaderComp from "../customize/component/LoaderComp.vue";
import EditQuizModal from "./modal/EditQuizModal.vue";
import IntegrationCarouselComp from "../Integrations/IntegrationCarouselComp.vue";
import UpgradePlanModal from "./modal/UpgradePlanModal.vue";
import CreateQuizCategory from "./modal/CreateQuizCategoryModal.vue";
import DeleteModal from "./modal/DeleteModal.vue";
import HeaderVue from "./Layout/Header.vue";
import TestPreviewModal from "../QuizTestModal/TestPreviewModal.vue";
import CreateQuizSkeleton from "./CreateQuiz/createQuizSkeleton.vue";
// import TestIndex from '../QuizTestModal/MainPage.vue';
// import TestPreviewModal from '../QuizTestModal/TestPreviewModal.vue'
export default {
  components: {
    // LoaderComp,
    CreateQuizCategory,
    EditQuizModal,
    UpgradePlanModal,
    IntegrationCarouselComp,
    HeaderVue,
    Button,
    DeleteModal,
    CreateQuizSkeleton,
    TestPreviewModal,
    FreightQuotMarginPrice: () =>
      import(
        /* webpackChunkName: "FreightQuotMarginPrice" */ "../quizPreviewComp/specialClients/FrieghtQuotMarginPrice.vue"
      ),
    GetStartedPagePreviewComp: () =>
      import(
        /* webpackChunkName: "GetStartedPagePreviewComp" */ "../NavbarPagePreview/GetStartedPagePreviewComp.vue"
      ),
    QuestionLeadsStripePage: () =>
      import(
        /* webpackChunkName: "QuestionLeadsStripePage" */ "../NavbarPagePreview/QuestionLeadsStripePage.vue"
      ),

    DuplicateQuizModal: () =>
      import(
        /* webpackChunkName: "DuplicateQuizModal" */ "./modal/DuplicateQuizModal.vue"
      ),
  },
  data() {
    return {
      // componentLoaded:true,
      deleteSpinner: false,
      loadMoreQuizzes: false,
      pagination: {
        current_page: 1,
        total: 0,
        per_page: 4,
      },
      DeleteQuiz: null,
      quizKey: "",
      QuizPreview: false,
      isDashboardLoading: true,
      // QuizKey:'0rRBNTyZYn0GA5ubsZHZm2',
      Dashboard: [],
      // Limitations: {},
      // plan: {},
      // user: {},
      upgradeModalTitle: "Upgrade Your Plan for More Quizzes",
      upgradeModalsubText:
        "You've hit your quiz limit – great work! To keep the momentum and grow further, upgrade your plan now.",
    };
  },
  methods: {
    async DeleteQuizHandle() {
      this.deleteSpinner = true;
      try {
        let data = {
          _token: this.GetTokenFromMetaTag,
          quizId: this.DeleteQuiz.id,
        };
        const response = await axios.post(`/removeUserQuiz`, data);
        if (response.status == 200) {
          this.$refs.deleteQuiz.close();
          this.updateDashboardData();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.deleteSpinner = false;
      }
    },
    checkLimitations(limitations) {
      if (limitations.text == "Questions" || limitations.text == "Products") {
        return limitations.planValue == 0 ? false : true;
      } else {
        return true;
      }
    },
    ...mapMutations(["setQuizKey", "setQuizTitle", "setUserAllAccountData"]),
    openCustomize(quiz) {
      const quizUrl = `/customize/create/${quiz.quizKey}`;
      let tab = "_blank";

      if (this.getUserType === "bc") {
        const token = localStorage.getItem("user_access_token");
        console.log("Tokenn for Big Commerce ",token)
        if (token) {
          window.open(`${quizUrl}?token=${token}`, tab);
        }
      } else {
        this.setQuizTitle(quiz.title);
        tab = this.getUserType === "shopify" ? "_self" : "_blank";
        window.open(quizUrl, tab);
      }
    },
    openShare(quiz) {
      let tab = this.getUserType == "shopify" ? "_self" : "_blank";
      window.open("/customize/share/" + quiz.quizKey, tab);
    },
    randomColor() {
      let color = "#";
      for (let i = 0; i < 6; i++) {
        const random = Math.random();
        const bit = (random * 8) | 0;
        color += bit.toString(16);
      }
      return color;
    },
    removeQuiz(quiz) {
      this.DeleteQuiz = quiz;
      this.$refs.deleteQuiz.open();
    },
    async duplicateQuiz(quiz) {
      const QuizIndex = this.Limitations.findIndex(
        (obj) => obj.text == "Quizzes"
      );
      if (QuizIndex > -1) {
        const planValue = this.Limitations[QuizIndex].planValue;
        const userValue = this.Limitations[QuizIndex].userValue;

        if (userValue < planValue) {
          this.$refs.DuplicateQuizModalRef.OpenModal(quiz);
        } else {
          this.$refs.UpgradeUserPlanModal.OpenModal();
        }
      } else {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    openCreateQuiz() {
      // eslint-disable-next-line no-unreachable
      const QuizIndex = this.Limitations.findIndex(
        (obj) => obj.text == "Quizzes"
      );
      if (QuizIndex > -1) {
        const planValue = this.Limitations[QuizIndex].planValue;
        const userValue = this.Limitations[QuizIndex].userValue;

        if (userValue < planValue) {
          this.$refs.createQuiz.open();
          // this.$router.push("/manage/create-quiz");
        } else {
          this.$refs.UpgradeUserPlanModal.OpenModal();
        }
      } else {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },

    openEditQuizModal(quiz) {
      this.$refs.EditQuizModalRef.OpenModal(quiz);
    },

    JumpToCreate(id) {
      this.setQuizKey(id);
      window.location.href = `/customize/quiz/create/${id}`;
    },
    ImageSrc(img) {
      if (img == "uploads/profile/1841662374867.jpg") {
        return "https://cdn.shopify.com/s/files/1/0554/9676/9614/files/default.png?v=1663245344";
      } else {
        return img.startsWith("upload") ? `/${img}` : img;
      }
    },
    async getDashboardData() {
      this.loadMoreQuizzes = true;
      try {
        let data = {
          page: this.pagination.current_page,
          per_page: this.pagination.per_page,
        };
        const response = await axios.post(`/loadQuizzes`, data);
        if (response.status == 200 && response.data.data.quizzes?.data.length) {
          this.Dashboard = [
            ...this.Dashboard,
            ...response?.data.data.quizzes.data,
          ];
          this.pagination.current_page =
            response?.data.data.quizzes.current_page + 1;
          this.pagination.total = response?.data.data.quizzes.total;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.loadMoreQuizzes = false;
        this.isDashboardLoading = false;
      }
    },
    openProducts() {
      this.$router.push("/manage/products");
    },

    updateDashboardData() {
      window.location.reload();
    },
    openUpgradePlanModal() {
      this.$refs.UpgradeUserPlanModal.OpenModal();
    },
    openPlansModal() {
      let webUsers = ["web", "bc", "wordpress"];
      if (webUsers.includes(this.getUserType)) {
        this.$router.push("/manage/account/pricing");
      }
      if (this.getUserType == "wix") {
        let url = `https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9`;
        window.open(url, "_blank");
      }
      if (this.getUserType == "shopify") {
        const envPath = process.env.VUE_APP_SHOPIFY_UPGRADE_PLAN_PATH;
        const url = `https://${this.UserAccountData.name}${envPath}manage/subscription/plans`;
        window.open(url, "_parent");
      }
    },
    openAccount() {
      this.$router.push("/manage/account");
    },
    resetDashboardData() {
      this.Dashboard = [];
      this.pagination = {
        current_page: 1,
        total: 0,
        per_page: 4,
      };
      return [];
    },
  },
  created() {
    this.getDashboardData();
  },
  mounted() {
    this.setQuizTitle("");
  },
  computed: {
    ...mapGetters([
      "GetTokenFromMetaTag",
      "getNavbarData",
      "getIsAccountDataLoading",
      "getUserAllAccountData",
    ]),
    getUserType() {
      return this.getNavbarData.userType;
    },
    UserAccountData() {
      return this.getNavbarData;
    },
    userAllAccountData() {
      return this.getUserAllAccountData;
    },
    getColumn() {
      let width = window.innerWidth;

      return width > 1500 ? "col-12 col-md-6 col-lg-4" : "col-12 col-md-6";
    },

    Limitations() {
      return this.userAllAccountData?.limitations || [];
    },
    plan() {
      return this.userAllAccountData?.plan || {};
    },
    user() {
      return this.userAllAccountData?.account || {};
    },
  },
};
</script>

<style scoped>
.quiz-title {
  color: var(--gray-28);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.DashboardMainStyles {
  min-height: calc(100vh - 150px);
}
.btn-profile-seeting {
  border: 1px solid #ffa201;
  color: #ffa201;
}
.btn-profile-seeting:hover {
  border: 1px solid #ffa201;
  color: #ffffff;
  background: #ffa201;
}
.Limitations >>> .progress-head {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.Limitations >>> .progress {
  background-color: #ffffff !important;
  height: 5px;
  border-radius: 1.25rem;
}
.Limitations >>> .bg-yello-theme {
  background-color: #ffa201 !important;
}
.Limitations >>> .bg-red-theme {
  background-color: #de5868 !important;
}

.search-input {
  width: 29%;
}
.search-input .input-group-append .input-group-text {
  background: #ffffff;
  color: #6d6b6d;
  border-left: none;
}

.scroll {
  max-height: 36px;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}

.youQuizzesTitle {
  font-size: 16px;
  font-weight: 400;
  color: #292929;
}

.youQuizzesValue {
  font-size: 20px;
  font-weight: 600;
  color: #292929;
}
.plan-name {
  color: #ffffff;
  font-weight: 700;
  font-size: 30px;
}
.plan-amount {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
}

.plan-amount .price {
  font-size: 38px;
  font-weight: 300;
}
.limitation-value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;

  letter-spacing: 0.04em;

  color: #b3afb6;
}

.UpgradeBtn {
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  background: transparent;
  border-radius: 6px;
  text-decoration: none;
  color: #ffffff;
}
.UpgradeBtn:hover {
  background: #ffffff;
  color: #ffa201;
}

.quizMainDev {
  box-shadow: rgba(16, 24, 40, 0.1) 0px 1px 3px 0px;
  border-radius: 12px;
  min-height: 300px;
  transition: all 0.3s ease;
  /* background-repeat: no-repeat;
    background-size: cover; */
}
.quizMainDev:hover {
  animation: breathing 2s infinite; /* Add the breathing animation on hover */
}

@keyframes breathing {
  0% {
    transform: scale(1); /* Start with the default scale */
  }
  50% {
    transform: scale(1.03); /* Scale up at 50% of the animation */
  }
  100% {
    transform: scale(1); /* Return to the default scale at the end */
  }
}
.quizMainDev .topSection {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  cursor: pointer;
}

.QuizDropdown >>> button {
  background: transparent !important;
  border: none;
  margin: 0;
  padding: 0;
}
.QuizDropdown >>> button::after {
  display: none;
}
.QuizDropdown >>> ul {
  /* background:#ffffff;
    box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
border-radius: 6px; */
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(14px);
  border-radius: 4px;
  margin: 0;
  padding: 0;
}
.QuizDropdown >>> li a {
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 4px 10px;
  margin-bottom: 5px;
}
.QuizDropdown >>> li a:hover {
  background: transparent;
}
.QuizDropdown >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  text-decoration: none !important;
}

.PreviewBtn {
  background: #e0dee2;
  box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.19);
  color: #6d6b6d;
}
.PreviewBtn:hover {
  background: #ffa201;
  color: #ffffff;
}

/* Account Stats Style */

.btn-profile-seeting {
  border: 1px solid #ffa201;
  color: #ffa201;
}
.btn-profile-seeting:hover {
  border: 1px solid #ffa201;
  color: #ffffff;
  background: #ffa201;
}
.Limitations >>> .progress-head {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.Limitations >>> .progress {
  background-color: #f4f3f5;
  height: 5px;
  border-radius: 1.25rem;
}
.Limitations >>> .bg-yello-theme {
  background-color: #ffa201 !important;
}

.search-input {
  width: 29%;
}
.search-input .input-group-append .input-group-text {
  background: #ffffff;
  color: #6d6b6d;
  border-left: none;
}

.scroll {
  max-height: 36px;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}

.youQuizzesTitle {
  font-size: 16px;
  font-weight: 400;
  color: #292929;
}
.youQuizzesValue {
  font-size: 20px;
  font-weight: 600;
  color: #292929;
}
.plan-name {
  color: #ffffff;
  font-weight: 700;
  font-size: 30px;
}
.plan-amount {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
}

.plan-amount .price {
  font-size: 38px;
  font-weight: 300;
}
.limitation-value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;

  letter-spacing: 0.04em;

  color: #b3afb6;
}

.UpgradeBtn {
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  background: transparent;
  border-radius: 6px;
  text-decoration: none;
  color: #ffffff;
}
.UpgradeBtn:hover {
  background: #ffffff;
  color: #ffa201;
}

.CreateButton {
  position: absolute;
  bottom: 28%;
  left: 17%;
  transform: translateX(-50%);
}

.createfirstQuiz {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: var(--Elements-30-33px, 8px);
  border-radius: 6px;
  border: 1px solid #8142be;
  background: var(--White, #fff);
  box-shadow: 0px 1px 0px 0px rgba(30, 28, 32, 0.2);
  color: #4d1b7e;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.createfirstQuiz:hover {
  color: #4d1b7e;
}
</style>
