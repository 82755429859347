<template>
  <div class="integrationDiv p-4" >
                        <div class="header-section d-flex justify-content-between">
                          <div class="integrationImgae bg-preload" style="height:70px;width:70px;border-radius: 14px;">
                              <div class="into-preload"></div>
                          </div>
                          <div class="planDiv bg-preload" style="height:21px;">
                           <div class="into-preload"></div>
                          </div>
                           </div>
                           <div class="mid-section pt-4 pb-3">
                            <div class="integration-title bg-preload" style="height:16px;">
                            <div class="into-preload"></div>
                            </div>
                            <div class="integration-desc bg-preload" style="height:40px;">
                              <div class="into-preload"></div>
                            </div>
                           </div>
                      </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.integrationDiv{

background: #FFFFFF;
box-shadow: 0px -2px 16px rgba(77, 27, 126, 0.1);
border-radius: 20px;
width:100%;
/* min-height:250px; */
height:100%;

}
</style>