<template>
  <div>
<div class="modal fade" id="UpgradePlanModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" v-bind="$attrs" ref="upgradePlanModal">

  <div class="modal-dialog modal-lg">
      <div class="modal-content " id="UpgradePlanModalContent" >


          <!-- Modal Content -->
          <div >

            <div class="" >
              <div class="container px-4">
                <div class="row  px-3 py-3 d-flex justify-content-end align-items-center">
                    <div>
                        <button
                        @click="CloseModal"
                        class="btn outline-none  outline-none border-0 "
                        style="cusrsor: pointer;color:#323232;"><i class="fa fa-times" aria-hidden="true"></i></button>

                    </div>
                </div>

          <div class="row pb-5">
              <div class="col-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center">
                  <LazyImage :src="'https://quizell.nyc3.cdn.digitaloceanspaces.com/website/upgradePlanBanner.png'" style="height:250px;width:250px;" alt="" />
              </div>
              <div class="col-12 col-sm-6 col-md-6 ">
                  <div class="row" style="text-align:start !important;">
                      <div class="col-12">
                          <p style="color:#292929;font-weight:600;font-size:22px;">{{titleText}}</p>
                      </div>
                      <div class="col-12">
                          <p style="color:#4d4950;font-weight:400;font-size:16px;" v-html="subTitleText"></p>
                      </div>
                      <div class="col-12 mt-2">
                          <Button  btnVariant="yellow"  @click="openUpgradePlan()">Upgrade Now</Button>
                         
                      </div>
                  </div>
              </div>
          </div>



              </div>
            </div>
          </div>

      </div>
    </div>
</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from 'jquery'
import Button from '../Layout/Button.vue';
export default {
    props:['title','subText'],

    components:  {
      Button
    },
    data(){
        return{
          titleText:this.title,
          subTitleText:this.subText,

        }
    },
methods:{
OpenModal(){

     $("#UpgradePlanModal").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
        // $('#UpgradePlanModal').modal('show')
    },
    CloseModal(){
         $('#UpgradePlanModal').modal('hide')
    },

    openUpgradePlan(){
        this.CloseModal()
        let webUsers = ['web','bc','wordpress']
           if( webUsers.includes(this.getUserType)){
            let url = window.location.origin + `/manage/account/pricing`
            window.open(url,"_self")
          // this.$router.push('/manage/account/pricing')
      }
      if(this.getUserType == 'shopify') {
        this.$emit("openPlansModalChild")
      } 
      if(this.getUserType == 'wix'){
         let url = `https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9`
      window.open(url,"_blank")
      }
       
    }


},
computed: {
    ...mapGetters(["GetTokenFromMetaTag","getNavbarData"]),
    getUserType() {
      return this.getNavbarData.userType
    }
  },
mounted() {
        // $(this.$refs.upgradePlanModal).on("show.bs.modal", this.getProductData)
    },
}
</script>

<style >
#UpgradePlanModalContent{
    border-radius:20px;
}
.QuestionsDiv{
    height:100%;
}

</style>
