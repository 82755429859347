<template>
  <div dir="ltr" class="customize__index">
    <!-- Loader -->
    <div class="" v-if="isLoading" style="min-height:500px;">
      <LoaderComp />
    </div>

    <!-- All Pages -->
    <div v-else>
      <div v-if="KeyNotValid">
         <transition name="quizFade" appear>
        <InvalidKeyPage />
         </transition>
      </div>
      <div v-else>
        <div v-if="getPreviewSettings && getPreviewSettings.expireStatus">
           <transition name="quizFade" appear>
          <ExpiredPage />
           </transition>
        </div>
        <!-- <preview-nav  :changeView="changeView" :key="count"></preview-nav> -->
        <div v-else :class="[isDesktopView?'desktop-preview':'mobile-preview']">
          
           <div
            v-if="CurrentSelectedPage.value == 'getStartedPage'"
            class="row m-0"
           
            dir="ltr"
            :key="countComp + 'getStarted'"
          >

            <div style="display: contents; ">
              <div class="col-12 d-flex justify-content-center p-0">
                 <!-- <transition name="quizFade" appear> -->
                   <GetStartedPreview
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :isTest="true"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              RoutePage='QuizFinal'
            />
             <!-- </transition> -->
              </div>
            </div>
           
          </div>
           <div
            v-if="CurrentSelectedPage.value == 'singleAnswer'"
            class="row m-0"
           
            dir="ltr"
            :key="countComp + 'singleAnswer'"
          >

            <div style="display: contents; ">
              <div class="col-12 d-flex justify-content-center p-0">
                 <!-- <transition name="quizFade" appear> -->
                   <SingleAnswerPreview
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :isTest="true"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
               RoutePage='QuizFinal'
            />
             <!-- </transition> -->
              </div>
            </div>
           
          </div>
           <div
            v-if="CurrentSelectedPage.value == 'multipleAnswer'"
            class="row m-0"
           
            dir="ltr"
            :key="countComp + 'multipleAnswer'"
          >

            <div style="display: contents; ">
              <div class="col-12 d-flex justify-content-center p-0">
                 <!-- <transition name="quizFade" appear> -->
                   <MultipleAnswerPreview
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :isTest="true"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
               RoutePage='QuizFinal'
            />
             <!-- </transition> -->
              </div>
            </div>
           
          </div>
          <div
            v-if="CurrentSelectedPage.value == 'leadPage'"
            class="row m-0"
           
            dir="ltr"
            :key="countComp + 'leadPage'"
          >

            <div style="display: contents; ">
              <div class="col-12 d-flex justify-content-center p-0">
                 <!-- <transition name="quizFade" appear> -->
                   <LeadPreview
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :isTest="true"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
               RoutePage='QuizFinal'
            />
             <!-- </transition> -->
              </div>
            </div>
           
          </div>
          
         
          <div
            v-if="CurrentSelectedPage.value == 'resultPage'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'result'"
          >
           <!-- <transition name="quizFade" appear> -->
            <ResultPreview :isDesktopView="isDesktopView" :apiToken="QuizKey" :isTest="true" 
            :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
            RoutePage='QuizFinal' />
             <!-- </transition> -->
          </div>
           <div
            v-if="CurrentSelectedPage.value == 'stripePage'"
            class="row m-0"
           
            dir="ltr"
            :key="countComp + 'stripe'"
          >

            <div style="display: contents; ">
              <div class="col-12 d-flex justify-content-center p-0">
                 <!-- <transition name="quizFade" appear> -->
                   <StripePreview
              :isDesktopView="isDesktopView"
              :isTest="true"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
               RoutePage='QuizFinal'
            />
             <!-- </transition> -->
              </div>
            </div>
            
           
          </div>
          <div
            v-if="CurrentSelectedPage.value == 'sliderPage'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'slider'"
          >
          <div style="display: contents; ">
              <div class="col-12 d-flex justify-content-center p-0">
                
            <SliderComp
            :isDesktopView="isDesktopView" :apiToken="QuizKey" :isTest="true" 
            :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
            RoutePage='QuizFinal'
            />
             <!-- </transition> -->
              </div>
            </div>
           
          </div>
          <div
            v-if="CurrentSelectedPage.value == 'endQuiz'"
            class="row m-0"
           
            dir="ltr"
            :key="countComp + 'endQuiz'"
          >

            <div style="display: contents; ">
              <div class="col-12 d-flex justify-content-center p-0">
                 <!-- <transition name="quizFade" appear> -->
                   <EndQuizPreview
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :isTest="true"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
               RoutePage='QuizFinal'
            />
             <!-- </transition> -->
              </div>
            </div>
           
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import LoaderComp from "../customize/component/LoaderComp.vue";

export default {
  components: {
    LoaderComp,
    
       GetStartedPreview:()=> import(/* webpackChunkName: "GetStartedPreview" */ '../quizPreviewComp/getStartedComp.vue'),
     SingleAnswerPreview:()=> import(/* webpackChunkName: "SingleAnswerPreview" */ '../quizPreviewComp/SingleAnswerComp.vue'),
     MultipleAnswerPreview:()=> import(/* webpackChunkName: "MultipleAnswerPreview" */ '../quizPreviewComp/MultipleAnswerComp.vue'),
     LeadPreview:()=> import(/* webpackChunkName: "LeadPreview" */ '../quizPreviewComp/LeadComp.vue'),
     ResultPreview:()=> import(/* webpackChunkName: "ResultPreview" */ '../quizPreviewComp/ResultComp.vue'),
     EndQuizPreview:()=> import(/* webpackChunkName: "EndQuizPreview" */ '../quizPreviewComp/EndQuiz.vue'),
     InvalidKeyPage:()=> import(/* webpackChunkName: "InvalidKeyPage" */ '../quizPreviewComp/InvalidKeyPage.vue'),
     ExpiredPage:()=> import(/* webpackChunkName: "ExpiredPage" */ '../quizPreviewComp/ExpiredPage.vue'),
     StripePreview:()=> import(/* webpackChunkName: "StripePreview" */ '../quizPreviewComp/StripePreviewComp.vue'),
     SliderComp: () => import(/* webpackChunkName: "SliderComp" */ '../quizPreviewComp/SliderComp.vue'),
  },
  props:{
    isDesktopValue:Boolean,
    QuizKey:String,
  },
  data() {
    return {
      count: 0,
      countComp: 0,
      isDesktopView: true,
      isLoading: false,
      KeyNotValid: false,
     
      windowWidth: window.innerWidth,
      animationType:"SlideIn",
    };
  },
  computed: {
    ...mapGetters(["getSelectedPage", "getTotalPages", "getPreviewSettings","getSelectedOptions"]),
    CurrentSelectedPage() {
      window.quizell.selectedQuestionOptions=this.getSelectedOptionsFromVuex.questionAnswer
      return this.getSelectedPage;
    },
    getSelectedOptionsFromVuex(){
    return JSON.parse(JSON.stringify( this.getSelectedOptions));
    },
    isExpired() {
      if (this.getPreviewSettings) return this.getPreviewSettings.expireStatus;
      return null
    },
    getIsAnimationEnabled(){
      if (this.getPreviewSettings) return this.getPreviewSettings.animation.show;
       return null
    },
    getAnimationType(){
      if (this.getPreviewSettings) {
        let {direction} = this.getPreviewSettings.animation

        if(direction == 'LTR') return `slide-right`
        if(direction == 'RTL') return `slide-left`
        if(direction == 'TTB') return `slide-down`
        if(direction == 'BTT') return `slide-up`
        if(direction == 'POP') return `zoom-in`
        if(direction == 'FLIP') return `flip-left`
      }
       return null
    },
    getAnimationSpeedinMillis(){
       if (this.getPreviewSettings) {
        let {speed} = this.getPreviewSettings.animation

        if (typeof speed === 'string') {
          speed = parseFloat(speed);
        if (isNaN(speed)) {
          return 0; 
        }
      }

      return speed * 1000;
       }
       return null
    },
  },



 async mounted() {
    
 this.resetQuiz()
  },

  methods: {
     async resetQuiz(){
this.RESET_STATE();
    this.ResetStoredData();
     await this.PopulatePreviewDataVuex(this.QuizKey);
     this.resetSavedLeadObj({ 
       address:{},
       custom_inputs:{},
            date:'',
            email:'',
            freeTextNote:[],
            full_name:'',
            website:'',
            organisation:'',
            phone_number:'',
            terms_conditions:false, fileUpload:[],
            quiz_analytic_id:null})
    this.resetPreviewPagesStack();
    await this.resetSelectedOptions();
    await this.resetRangeSliders();
  },
    // resizeHandler(e) {
    //   if (e.target.window.innerWidth > 769) {
    //     this.isDesktopView = true;
    //   } else {
    //     this.isDesktopView = false;
    //   }
    // },
    ...mapMutations([
      "ResetStoredData",
      "setSelectedPage",
      "setPreviewProgressBar",
      "setTotalPreviewPages",
      "setPreviewSettings",
      "resetSelectedOptions",
      "resetRangeSliders",
      "resetPreviewPagesStack",
      "RESET_STATE",
      "setTranslation",
      "resetQuizStarted",
      "resetSavedLeadObj"
    ]),

    async PopulatePreviewDataVuex(QuizKey) {
      this.isLoading = true;
      this.resetQuizStarted();
      try {
        let lang=this.$route.query.lang;
        let data = {
          api_token: QuizKey,
          lang:lang
        };
        let firstPage;
        const response = await axios.get(`/api/loadQuiz`, { params: data });
        if (response.status == 200 && response.data.status == "success") {
          const totalPages = this.rearrangeOrder(response.data.data.totalPages);
          const progressBarValue = response.data.data.progressBar;
          const previewSettings = response.data.data.setting;
          window.quizell.allQuestions=totalPages
          if (totalPages.length > 0) {
            firstPage = totalPages[0];
          }
          await this.setTotalPreviewPages(totalPages);
          await this.setSelectedPage(JSON.parse(JSON.stringify(firstPage)));
          await this.setPreviewProgressBar(progressBarValue);
          await this.setTranslation(previewSettings.translation);
          await this.setPreviewSettings(previewSettings);
        } else if (response.status == 200 && response.data.status == "fail") {
          this.KeyNotValid = true;
        }
      } catch (error) {
        this.KeyNotValid = true;
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    rearrangeOrder(arr) {
      const tempArr = [...arr];
      const newArr = tempArr.sort((a, b) => a.order - b.order);
      return newArr;
    },
  },
  watch: {
   
    getTotalPages(newVal, oldVal) {
      if (oldVal.length < newVal.length) {
        this.count++;
      }
    },
    CurrentSelectedPage() {
      this.countComp++;
    },
    isDesktopValue(){
      this.isDesktopView = this.isDesktopValue
    },
  
  },
};
</script>

<style>
.default-bg {
  background: #cccace;
}
.cursorPointer {
  cursor: pointer;
}
.div-disable {
  pointer-events: none;
  opacity: 0.4;
}
.image-division {
  height: 100%;
  /* display: flex;
    align-items: center;
    justify-content: center; */
}
.ql-editor {
  min-height: auto !important;
  height: auto !important;
}
.ql-container {
  height: auto !important;
  min-height: auto !important;
}
.sub-title {
  font-size: 14px;
  color: #8c898f;
}
.page-setting-btn,
.page-setting-btn i {
  color: #8c898f;
  font-weight: 500;
}
.close-icon {
  position: absolute;
  right: 23px;
  top: 23px;
  background: transparent;
  border: none;
}
.deleteBlock {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
  z-index: 10;
}
.SelectedImageStyle {
  position: absolute;
  top: 0;
  right: 0;

}
#preview div {
  word-break: break-word !important;
}
.navbar__icon-container.active {
  background: rgb(179 175 182 / 30%) !important;
}

.big-div {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
  padding: 0px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
}

.Tab:hover {
  transform: translate3d(0, -3px, 0);
  -webkit-transform: translate3d(0, -3px, 0);
}
.desktop-preview{
  height: 570px;
    width: 840px;
    margin-top: 20px;
    border:1px solid  var(--border-color);
  border-radius:var(--border-radius);
  overflow:hidden;
}
.mobile-preview{
  width:350px; margin:0 auto;
  margin-top: 20px;
  border:1px solid  var(--border-color);
  border-radius:var(--border-radius);
  overflow:hidden;
}
</style>
